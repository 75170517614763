.work-wrapper {
  padding: var(--spacing-48) var(--spacing-12);
  border-radius: var(--spacing-24);
}

.work-wrapper .card {
  border: 0;
  padding: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.work-wrapper .card-body {
  margin-top: var(--spacing-12);
}
