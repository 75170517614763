.product-wrapper {
  color: var(--color-white);
  padding: var(--spacing-48) var(--spacing-12);
  border-radius: var(--spacing-24);
}

.product-wrapper .card {
  border: 0px;
  padding: var(--spacing-40) var(--spacing-32);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.product-wrapper .card p.body {
  padding: var(--spacing-12) var(--spacing-24) 0;
}
