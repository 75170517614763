@import url("https://fonts.googleapis.com/css2?family=Playball&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Quicksand:wght@500&display=swap");
@import "assets/css/common.css";

* {
  font-family: "Poppins", sans-serif;
}

.font-poppins {
  font-family: "Poppins", sans-serif;
}

body {
  background-color: rgb(249 251 249) !important;
}
