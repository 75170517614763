.faq {
  margin-top: var(--spacing-64);
  background-color: transparent;
}

.question {
  cursor: pointer;
  color: var(--color-blue-500);
}

.answers {
  color: var(--color-black);
  margin: 0 0 10px 0;
  max-height: 0;
  overflow: hidden;
  position: relative;
  opacity: 0;
  -webkit-transition: 0.7s ease;
  -moz-transition: 0.7s ease;
  -o-transition: 0.7s ease;
  transition: 0.7s ease;
}

.plus {
  font-size: var(--spacing-24);
  float: right;
}

.minus {
  display: none;
  font-size: var(--spacing-24);
  float: right;
}

.questions {
  display: none;
}

.questions:checked ~ .answers {
  max-height: 50vh;
  opacity: 1;
}
