.static-header .navbar {
  min-height: var(--spacing-80);
  background-color: var(--color-blue-100);
  box-shadow: none !important;
}

.logo {
  width: var(--spacing-32) !important;
  height: var(--spacing-32) !important;
}

.static-header .home-page-title {
  font-size: var(--spacing-24) !important;
  color: var(--color-blue-500) !important;
}

.static-header .navbar .fixed-after {
  height: 30px;
}

.static-header .home-page-title.after {
  font-size: 18px !important;
}

.static-header .navbar-toggler {
  border: none;
}

.static-header .add-on-text {
  color: var(--color-white);
  background-color: var(--color-violet-400);
  text-align: center;
  padding-top: var(--spacing-12);
  padding-bottom: var(--spacing-12);
  font-weight: 500;
}
