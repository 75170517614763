.section-one-wrapper {
  padding-top: var(--spacing-32);
  /* color: var(--color-blue-500); */
  /* background-color: var(--color-blue-100); */
}

.section-one-wrapper ul i.fa {
  color: var(--color-blue-500);
}

.banner-img {
  transform: scale(1.2);
}

@media (max-width: 576px) {
  .section-one-wrapper {
    /* padding-top: var(--spacing-64); */
  }
}
