.card-testimonial {
  border: 0px solid #fff;
  padding: var(--spacing-12) var(--spacing-16);
  background-color: var(--color-white);
  min-width: 25rem;
  border-radius: var(--spacing-24);
  text-align: center;
}

.card-testimonial .testimonial-img {
  width: var(--spacing-32);
  height: var(--spacing-32);
  border-radius: 50%;
  border: 1px solid var(--color-violet-400);
  margin-right: var(--spacing-8);
  object-fit: cover;
}

.inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 10rem;
}

.wrapper {
  position: absolute;
  display: flex;
}

div.testimonials {
  animation: swipe var(--speed) linear infinite backwards;
}

@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}
