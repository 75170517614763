.about-us-wrapper {
  color: var(--color-white);
  padding: var(--spacing-48) var(--spacing-128);
  margin: var(--spacing-64) 0;
  border-radius: var(--spacing-24);
  background-color: var(--color-blue-500);
  text-align: center;
}

.about-us-wrapper .card {
  border: 0px;
  padding: var(--spacing-48) var(--spacing-32);
  background-color: var(--color-blue-500);
}

.about-us-wrapper .card p.body {
  padding: var(--spacing-24) var(--spacing-24);
  line-height: var(--spacing-32);
}

@media (min-width: 577px) {
  .about-us-wrapper .card p.body {
    padding: var(--spacing-24) var(--spacing-128);
  }
}
