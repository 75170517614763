.footer {
  background-color: var(--color-blue-500);
  color: var(--color-white);
  padding: var(--spacing-48) var(--spacing-12);
}

.footer .row-subheader {
  font-size: var(--font-size-bigger) !important;
  font-weight: 400;
}

.footer .row-subheader {
  font-size: var(--font-size-bigger) !important;
  font-weight: 400;
}

.footer .gmc-logo {
  height: var(--spacing-48) !important;
}

.footer .row-list {
  padding-left: 0;
}

.footer .row-list li a {
  color: var(--color-white) !important;
  font-size: var(--font-size-big) !important;
  font-weight: 300 !important;
}
